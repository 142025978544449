import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/gjuoun/Code/JS/docz-test/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "特性"
    }}>{`特性`}</h2>
    <h3 {...{
      "id": "app级别的特性："
    }}>{`app级别的特性：`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`多语言（暂时实现简体中文和英文）`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`多主题（暂时实现浅色与暗黑风格）`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "当前版本新增的计算器通用特性："
    }}>{`当前版本新增的计算器通用特性：`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`计算器表盘多开`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`历史记录`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`自定义参数管理`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`自定义公式（表达式）管理`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`光标系统`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`自定义函数`}</p>
      </li>
    </ul>
    <p>{`Image test:
`}<img alt="alt text" src="https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      